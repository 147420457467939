// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header';
import Content from './Content';
import { fetchBatteryByKey } from '../../slices/batteries'
import { LOADING } from '../../utils/constant';
import PageLoader from '../../components/PageLoader';

export default function Details() {
    const { batteryKey } = useParams();
    const { battery, loading } = useSelector((state) => state.batteries)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchBatteryByKey(batteryKey))
    }, [ batteryKey, dispatch ])

    return (
        <Fragment>
            {loading === LOADING.PENDING && <PageLoader withOverlay />}

            <Header />
            <Content battery={battery} />
        </Fragment>
    )

};
