import { createAction } from '@reduxjs/toolkit';

export const hydrate = () => async (dispatch) => {
  dispatch(
    createAction('HYDRATE_SECURITY_STATE')({
      cspNonce:
        document.querySelector('meta[property=csp-nonce]') &&
        document.querySelector('meta[property=csp-nonce]').getAttribute('content')
    })
  );
};
