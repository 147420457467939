export const config = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    redirectUri: process.env.REACT_APP_REDIRECTURI,
    scopes: [process.env.REACT_APP_SCOPES_API],
    authority: process.env.REACT_APP_AUTHORITY
  }
};

export const loginRequest = {
  scopes: process.env.REACT_APP_SCOPES_API.split(" ")
};
