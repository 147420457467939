export const SOC_REPORTED = [
  { from: 0, to: 10 },
  { from: 10, to: 20 },
  { from: 20, to: 30 },
  { from: 30, to: 40 },
  { from: 40, to: 50 },
  { from: 50, to: 60 },
  { from: 60, to: 70 },
  { from: 70, to: 80 },
  { from: 80, to: 90 },
  { from: 90, to: 100 }
];

export const SOH_REPORTED = [
  { from: 0, to: 70 },
  { from: 70, to: 80 },
  { from: 80, to: 85 },
  { from: 85, to: 90 },
  { from: 90, to: 95 },
  { from: 95, to: 100 }
];
export const SOC_OBJECT = {
  0: '0-10%',
  1: '10-20%',
  2: '20-30%',
  3: '30-40%',
  4: '40-50%',
  5: '50-60%',
  6: '60-70%',
  7: '70-80%',
  8: '80-90%',
  9: '90-100%'
};
export const SOH_OBJECT = {
  0: '0-70%',
  1: '70-80%',
  2: '80-85%',
  3: '85-90%',
  4: '90-95%',
  5: '95-100%'
};
export const FUNCTIONS = { 'DIAG_AND_ADJUST': 'Diagnostic & préparation', 'ADJUST': 'Préparation' };
export const END_CYCLE = { 'USAGE': 'Pleine charge', 'DISPOSAL': 'Rebut', 'STORAGE': 'Stockage', 'TRANSPORTATION': 'Transport' };
export const LOADING = { PENDING: 'pending', IDLE: 'idle' };

export const FONT_PRIMARY = 'Public Sans, sans-serif';
export const SORT_FIELDS = { op_date: 2 };
