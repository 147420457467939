import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import { Card, Typography } from '@mui/material';
import { formatDate } from '../../../utils/misc';
import { END_CYCLE, FUNCTIONS } from '../../../utils/constant';

const BoxTitle = ({ title }) => <div align="center"  >
    <Typography variant="h6" color="primary" noWrap>
        {title}
    </Typography>
</div>

const BoxItem = ({ label, value }) => <div align="center"  >
    <Grid container sx={{ my: 4, mx: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item>
            <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                {label}&nbsp;:&nbsp;
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body2">
                {value}
            </Typography>
        </Grid>

    </Grid>
</div>

const format_voltage = (v) => [undefined, null].includes(v)
    ? null
    : `${v} V`

const format_capacity = (Ah) => [undefined, null].includes(Ah)
    ? null
    : `${Ah} Ah`


const format_percents = (percents) => [undefined, null].includes(percents)
    ? "N/A"
    : percents + "%"

export default function Content({ battery }) {
    return (
        <Fragment>
            <Grid sx={{ mb: 2 }} container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={2} >
                    <BoxTitle title={"Identification"} />
                </Grid>
                <Grid item xs={2} >
                    <BoxTitle title={"Opération"} />
                </Grid>
                <Grid item xs={2} >
                    <BoxTitle title={"Données fabricant"} />
                </Grid>
                <Grid item xs={2} >
                    <BoxTitle title={"Mesures"} />
                </Grid>
                <Grid item xs={2} >
                    <BoxTitle title={"Traçabilité"} />
                </Grid>

            </Grid>
            <Grid container spacing={1} justifyContent="center">
                <Grid item xs={2}  >
                    <Card>
                        <BoxItem label={"Num. série"} value={battery?.batt_serial_num} />
                        <BoxItem label={"Type"} value={battery?.batt_type} />
                    </Card>
                </Grid>

                <Grid item xs={2}>
                    <Card>
                        <BoxItem label={"Date"} value={formatDate(battery?.op_date)} />
                        <BoxItem label={"Mode"} value={FUNCTIONS[battery?.operation]} /> 
                        <BoxItem label={"Fin de cycle"} value={END_CYCLE[battery?.setpoint]} />
                        <BoxItem label={"SOC relevé"} value={format_percents(battery.soc_value)} />
                        <BoxItem label={"SOH relevé"} value={format_percents(battery.soh_value)} />
                        <BoxItem label={"Equilibrage"} value={battery?.op_cell_balance === 0 ? "NOK" : "OK"} />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <BoxItem label={"Fabricant"} value={battery?.manufacturer} />
                        <BoxItem label={"Date fabrication"} value={formatDate(battery?.smb_manufacturer_date, "dd/MM/yyyy")} />
                        <BoxItem label={"Nom produit"} value={battery?.smb_devicename} />
                        <BoxItem label={"Tension nominale"} value={format_voltage(battery?.design_u_v)} />
                        <BoxItem label={"Capacité nominale"} value={format_capacity(battery?.design_q_ah)} />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <BoxItem label={"Tension batterie"} value={format_voltage(battery?.u_v)} />
                        <BoxItem label={"Tension cellule 1"} value={format_voltage(battery?.cell1_u_v)} />
                        <BoxItem label={"Tension cellule 2"} value={format_voltage(battery?.cell2_u_v)} />
                        { battery?.cell3_u_v && <BoxItem label={"Tension cellule 3"} value={format_voltage(battery?.cell3_u_v)} /> }
                        { battery?.cell4_u_v && <BoxItem label={"Tension cellule 4"} value={format_voltage(battery?.cell4_u_v)} /> }
                        <BoxItem label={"Cycle de charge"} value={battery?.smb_cycle_count} />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <BoxItem label={"Gateway"} value={battery?.gate_id} />
                        <BoxItem label={"Chassis"} value={battery?.svsr_id} />
                        <BoxItem label={"Voie"} value={battery?.svsr_slotnum} />
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    );
}
