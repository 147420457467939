import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";

import { CustomNavigationClient } from "./utils/NavigationClient";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import PageLoader from "./components/PageLoader";
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store';
import { msal as msalInstance } from './slices/user';
import { addToken } from './slices/user';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';

// components
import Header from "./components/Header"

//pages
import Login from './pages/Login';
import Batteries from './pages/Batteries'
import Detail from './pages/Detail';
import NotFound from './pages/NotFound';

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => { setFirstRender(false) }, []);

  return firstRender
    ? null
    : children;
}

const Dashboard = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (isAuthenticated) {
      instance.setActiveAccount(instance.getAllAccounts()[0])
      dispatch(addToken(instance.getActiveAccount()))
    }
  }, [isAuthenticated, dispatch, instance]);

  return isAuthenticated
    ? (
      <Fragment>
        <Header enableHelpdesk={!user.isUsingKioskTerminal}/>
        <Box sx={{ height: "inherit", pt: 12 }}>
          <Routes>
            <Route exact path="/" element={<Batteries />}></Route>
            <Route path="/detail/:batteryKey" element={<Detail />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </Fragment>
    )
    : <PageLoader/>
}

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <ClientSideNavigation pca={msalInstance}>
            <MsalProvider instance={msalInstance}>
              <AuthenticatedTemplate >
                <Dashboard/>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </MsalProvider>
          </ClientSideNavigation>
        </ThemeConfig>
      </Provider>
    </BrowserRouter>
  )
}
export default App