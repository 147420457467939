import React from 'react';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();

    const goToHome = () => navigate('/', { replace: false })

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2 }}>
            <div>
                <Button color="primary" variant="contained" onClick={goToHome}>
                    Retour
                </Button>
            </div>
            <div><Typography variant="h4" color="#676767" noWrap>Détail de la batterie</Typography></div>
            <div>
            </div>
        </Grid>
    );
}
