import { combineReducers } from 'redux';
import batteriesReducer from './slices/batteries';
import securityReducer from './slices/security/reducer';
import userReducer from './slices/user';

import { hydrate as hydrateSecurityState } from "./slices/security/actions"
import { configureStore } from '@reduxjs/toolkit'

const reducer = combineReducers({
  batteries: batteriesReducer,
  security: securityReducer,
  user: userReducer
})

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

store.dispatch(hydrateSecurityState())

export {
  store,
  reducer
}