
import { Fragment } from 'react';
import {
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from '@mui/material';
import { styled } from '@material-ui/styles';

const InputLabelStyle = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.disabled,
  margin: -7
}));

const Option = styled(MenuItem)(({ theme }) => ({
  display: 'block',
  paddingLeft: 5,
  margin: 5,
  fontSize: 14,
  with: '100%',
  fontWeight: "normal",
  
  "&.Mui-selected": {
    fontWeight: "bold"
  }
}))

const SelectFilter = ({ onSaveFilter, name, selection, placeholder, options }) => {
  const handleChange = (event) => {
    onSaveFilter(name, event.target.value || [])
  };

  return (
    <div>
      <FormControl sx={{ width: 200, py: 5 }}>
        <InputLabelStyle id="demo-multiple-chip-label">{placeholder}</InputLabelStyle>
        <Select
          style={{ height: 40 }}
          multiple
          value={selection || []}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => !!!selected
            ? null
            : (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} sx={{ maxWidth: 70 }} size="small" label={options[value]} />
                ))}
              </Box>
            )
          }>
          {Object.keys(options).map((k) => (
            <Option key={k} value={k} selected={selection || [].includes(k)}>
              <Fragment>
                {options[k]}
              </Fragment>
            </Option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectFilter