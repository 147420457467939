import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";

export default function PageLoader({
  color = "inherit",
  withOverlay = false,
}) {
  const classes = useStyles();

  if (withOverlay) {
    return (
      <div className={classes.overlay}>

        <CircularProgress
          color={color}
          className={classes.pageLoader}
          data-cy="page-loader"
        />

      </div>
    );
  }

  return (
    <CircularProgress
      color={color}
      className={classes.pageLoader}
      data-cy="page-loader"
    />
  );
}
