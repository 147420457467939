import React, { useEffect, useState } from "react";
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
  useTheme
} from "@material-ui/core";
import DateRangeIcon from "@mui/icons-material/DateRange";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { fr } from "date-fns/locale";

export default function DatePickerFilter({ startDate, endDate, onSaveFilter }) {
  const theme = useTheme()
  const dateFormat = "DD/MM/YYYY";

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedRange, setSelectedRange] = useState({
    low: endDate,
    high: startDate
  })

  const onAdornmentClick = e => {
    setAnchorEl(e.currentTarget)
  };

  const onInputChange = e => {
    let [startDate, endDate] = e.target.value.split("-").map(elm => elm.trim());
    onSelectDateRanges({
      startDate,
      endDate
    })
  };

  const onPopoverClose = (e, reason) => {
    setAnchorEl(null)
    onSaveFilter(selectedRange.low, selectedRange.high)
  };

  const onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;
    startDate = moment(startDate);
    endDate = moment(endDate);

    setSelectedRange({
      low: startDate.isValid() ? startDate.toDate() : undefined,
      high: endDate.isValid() ? endDate.toDate() : undefined
    })
  };

  const asInputValue = ({ low, high }) => [low, high]
  .filter(e => !!e)
  .map(d => moment(d).format(dateFormat))
  .join("-")

  useEffect(() => {
    setSelectedRange({
      low: moment(startDate).isValid() ? startDate : undefined,
      high: moment(endDate).isValid() ? endDate : undefined
    })
  }, [startDate, endDate])

  return (
    <div>
      <TextField
        variant="outlined"
        placeholder="Date"
        fullWidth={true}
        value={asInputValue(selectedRange)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        inputProps={{
          style: {
            height: 40,
            padding: '0px 5px',
          },
        }}
        style={{
          width: 200, marginBottom: 2
        }}
        onChange={onInputChange}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={onPopoverClose}
      >
        <div>
          <DateRange
            ranges={[{
              startDate: selectedRange.low,
              endDate: selectedRange.high,
              key: "selection"
            }]}
            rangeColors={[theme.palette.primary.main]}
            locale={fr}
            onChange={onSelectDateRanges}
            maxDate={new Date()}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            scroll={{ enabled: true }}
          />
        </div>
      </Popover>
    </div>
  );

}

