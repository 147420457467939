import React from 'react';

const search_button_style = {
  borderRadius: '6px',
  padding: '5px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '13px',
  cursor: 'pointer',
  backgroundColor: 'white',
  margin: '10px',
  fontWeight: 'bold',
  textTransform: 'uppercase'
}

const FilterButton = (props) => {
  const variant_styles = {
    apply: {
      color: 'rgb(155, 209, 101)',
      border: '1px solid rgb(155, 209, 101)'
    },

    reset: {
      color: '#676767',
      border: '1px solid rgba(103, 103, 103, 0.5)'
    },
  }

  let variant = "apply";
  let actual_props = props

  if (!!props["variant"]) {
    let {variant: the_variant, ...cleaned_up_props} = props
    variant = the_variant
    actual_props = cleaned_up_props
  }
  
  return (<button style={{...search_button_style, ...(variant_styles[variant] || {})}} {...actual_props}></button>)
}

export default FilterButton