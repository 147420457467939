// eslint-disable-next-line react-hooks/exhaustive-deps

import React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import AscendingSort from '@mui/icons-material/ArrowDropUp';
import DescendingSort from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@material-ui/core';

const SortButton = styled('span')(({ theme }) => ({
  whiteSpace: 'nowrap',
  width: '100%',
  '&:hover': {
    cursor: 'pointer',
  }
}));

const styles = {
  fontStyle: "italic",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center'
}

const SortIcon = ({ direction, ...props }) => direction === undefined || direction === 1
  ? null
  : (direction === 2 ? <DescendingSort {...props} /> : <AscendingSort {...props} />)

const HeaderCell = ({ title, help, enableSort, sortDirection, onSortDirectionChanged }) => (
  <div style={styles}>
    {
      !!enableSort
        ? (
          <SortButton onClick={onSortDirectionChanged}>
            {title}
            <SortIcon direction={sortDirection} />
          </SortButton>
        )
        : title
    }

    <Tooltip title={help}>
      <IconButton><HelpIcon /></IconButton>
    </Tooltip>
  </div>
)

export default HeaderCell