import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBatteries } from '../api/ApiCall';
import { SOH_REPORTED, SOC_REPORTED, LOADING, SORT_FIELDS } from '../utils/constant';
import { formatDate } from '../utils/misc';
import moment from 'moment';

const DBToList = (values) => {
  return values.map((item, index) => (item === {} ? {} : {
    ...item,
    index,
    batteryKey: `${item.batt_serial_num}_${formatDate(item.op_date, 't')}`,
    op_date: item.op_date ? new Date(item.op_date) : '',
    soh: SOH_REPORTED.findIndex((soh) => item.soh >= soh.from && item.soh <= soh.to),
    soc: SOC_REPORTED.findIndex((soc) => item.soc >= soc.from && item.soc <= soc.to),
    soh_value: item.soh,
    soc_value: item.soc
  }))
};

const parseBatteryKey = (batteryKey) => {
  const key_parts = batteryKey.split('_')
  if (key_parts.length === 2) {
    const [serial_num, ts] = key_parts
    const batt_serial_num = parseInt(serial_num, 10);
    const operation_timestamp = moment.unix(ts)
    if (!isNaN(batt_serial_num) && operation_timestamp.isValid()) {
      return {
        batt_serial_num,
        op_date: operation_timestamp
      }
    }
  }

  throw Error(`invalid battery key ${batteryKey}`)
}
export const fetchBatteryByKey = createAsyncThunk('Battery/fetch_by_key', async (batteryKey, { getState, rejectWithValue }) => {
  try {
    const { batteries: { batteries, battery } } = getState();
    // is it the one that was previously looked at ?
    if (battery.batteryKey === batteryKey) {
      return battery
    }

    // ok it's not, but do we already have it in memory then ?
    if (batteries.length > 0) {
      const from_cache = batteries.find((b) => `${b.batteryKey}` === `${batteryKey}`)
      if (!!from_cache) {
        return from_cache
      }
    }

    // we don't: fetch it
    const { batt_serial_num, op_date } = parseBatteryKey(batteryKey)
    const { items } = await getBatteries({ page: 0, pageSize: 1, filters: { batt_serial_num, op_date: op_date.format() }, sort: { batt_serial_num: 1, soc: 1, soh: 1, op_date: 2 } });
    if (items.length > 0) {
      return DBToList([ items[0] ])[0]
    }

    // couldn't find it anywhere
    return {}
  } catch (e) {
    return rejectWithValue(e);
  }
}
);


export const retrieveBatteries = createAsyncThunk('Batteries/retrieve', async ({ page, pageSize, filters, sort }, { getState, rejectWithValue }) => {
  try {
    const { items, total } = await getBatteries({ page, pageSize, filters, sort });
    return { items, total, page, pageSize, filters, sort };
  } catch (e) {
    return rejectWithValue(e);
  }
}
);

export const gotoPage = createAsyncThunk('Page/open', async (page, { getState, rejectWithValue }) => {
  try {
    const { batteries: { pageSize, filters, sort } } = getState();
    const { items, total } = await getBatteries({ page, pageSize, filters, sort });
    return { items, total, page, pageSize, filters, sort };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const changePageSize = createAsyncThunk('Page/changeSize', async (newPageSize, { getState, rejectWithValue }) => {
  try {
    const { batteries: { page, filters, sort } } = getState();
    const { items, total } = await getBatteries({ page, pageSize: newPageSize, filters, sort });
    return { items, total, page, pageSize: newPageSize, filters, sort };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const applyFilters = createAsyncThunk('Filters/apply', async (filters, { getState, rejectWithValue }) => {
  try {
    const page = 0
    const pageSize = 10

    const { batteries: { sort } } = getState();
    const { items, total } = await getBatteries({ page, pageSize, sort, filters });
    return { items, total, page, pageSize, filters, sort };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const resetFilters = createAsyncThunk('Filters/reset', async (_, { getState, rejectWithValue }) => {
  try {
    const filters = {}
    const page = 0
    const pageSize = 10

    const { batteries: { sort } } = getState();
    const { items, total } = await getBatteries({ page, pageSize, sort, filters });
    return { items, total, page, pageSize, filters, sort };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const applySort = createAsyncThunk('Sort/apply', async (sort, { getState, rejectWithValue }) => {
  try {
    const { batteries: { page, pageSize, filters } } = getState();
    const { items, total } = await getBatteries({ page, pageSize, sort, filters });
    return { items, total, page, pageSize, filters, sort };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const resetSort = createAsyncThunk('Sort/reset', async ({ getState, rejectWithValue }) => {
  try {
    const sort = SORT_FIELDS
    const { batteries: { page, pageSize, filters } } = getState();
    const { items, total } = await getBatteries({ page, pageSize, sort, filters });
    return { items, total, page, pageSize, filters, sort };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const cleanCache = createAsyncThunk('Battries/clean', async () => ({}));

const acceptSearchResults = (state, { payload }) => {
  state.loading = LOADING.IDLE;
  state.batteries = [...DBToList(payload.items)];
  state.total = payload.total;
  state.page = payload.page;
  state.pageSize = payload.pageSize;
  state.filters = payload.filters;
  state.sort = payload.sort;
}

const markLoading = (state) => {
  state.loading = LOADING.PENDING;
}
const markError = (state, action) => {
  state.loading = LOADING.IDLE;
  state.error = action.error;
}

const slice = createSlice({
  name: 'batteries',
  initialState: {
    batteries: [],
    filters: {},
    sort: SORT_FIELDS,
    total: 0,
    page: 0,
    pageSize: 10,
    battery: {},
    loading: LOADING.IDLE,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    [
      retrieveBatteries,
      applyFilters,
      resetFilters,
      applySort,
      resetSort,
      gotoPage,
      changePageSize
    ].forEach(action => {
      builder
        .addCase(action.pending, markLoading)
        .addCase(action.fulfilled, acceptSearchResults)
        .addCase(action.rejected, markError)
    })

    builder
      .addCase(cleanCache.fulfilled, (state) => {
        state.filters = {};
        state.batteries = [];
        state.user = null;
      })
      .addCase(fetchBatteryByKey.pending, markLoading)
      .addCase(fetchBatteryByKey.fulfilled, (state, { payload }) => {
        state.loading = LOADING.IDLE;
        state.battery = payload;
      })
      .addCase(fetchBatteryByKey.rejected, markError)
  }
});

const { reducer } = slice
export default reducer