import React from "react";
import { TextField } from "@material-ui/core";
import { styled } from '@mui/material/styles';

const TextFieldStyle = styled(TextField)({
  width: 200,
  padding: 1
});

export default function InputFilter({ onChange, name, placeholder, value }) {
  const handleChange = (e) => {
    onChange(
      name,
      (e.target.value || "").length === 0
        ? ""
        : e.target.value
    )
  }

  return (
    <TextFieldStyle id="outlined-basic"
      inputProps={{
        style: {
          height: 40,
          padding: '0 14px',
        },
      }}
      variant="outlined"
      placeholder={placeholder}
      value={value || ""}
      onBlur={handleChange}
      onChange={handleChange}
    />
  );
};
