import { useRef, useState } from 'react';
// material
import { Button, Box, Divider, Typography, Avatar, IconButton } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from 'react-redux';
// components
import MenuPopover from '../MenuPopover';
import { cleanCache } from '../../slices/batteries';

export default function AccountPopover() {
  const { instance } = useMsal();
  const { user } = useSelector((state) => state.batteries)
  const dispatch = useDispatch();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [account] = useState(null)

  const handleLogout = () => {
    dispatch(cleanCache())
    instance.logoutRedirect();
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ "marginBottom": "10%" }}>

      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            }
          })
        }}
      >
        <Avatar src={account?.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button onClick={handleLogout} fullWidth color="primary" variant="outlined">
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </div>
  );
}
