import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  cspNonce: undefined
};

export default createReducer(initialState, {
  HYDRATE_SECURITY_STATE: (state, { payload }) => ({
    ...state,
    ...payload
  })
});
