// eslint-disable-next-line react-hooks/exhaustive-deps

import React from 'react';
import { alpha, styled } from '@material-ui/core';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


const IconButtonStyle = styled(IconButton)(({ theme }) => ({
    height: 35,
    width: 35,
    borderRadius: 20,
    margin: "0px 10px 0px 10px",
    background: alpha(theme.palette.primary.main, 0.1)

}));

const ExportButton = ({ onClick }) => {
    return (
        <Tooltip title={"Rafraîchir la page"}>
            <IconButtonStyle onClick={onClick} color="primary" variant="outlined" >
                <RefreshIcon />
            </IconButtonStyle>
        </Tooltip>

    )
};

export default ExportButton