import axios from 'axios';
import { SOC_OBJECT, SOH_OBJECT } from '../utils/constant';
import moment from 'moment';
import { msal } from '../slices/user'
import { loginRequest } from '../config'
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL })

api.interceptors.request.use( async (request) => {
  const accessToken = await msal.acquireTokenSilent({
    ...loginRequest,
    account: msal.getActiveAccount(),
  }).then((response) => response.accessToken)
  .catch((error) => {
    if (error.errorCode && InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
      return msal.acquireTokenRedirect({
        ...loginRequest,
        account: msal.getActiveAccount()
      });
    }
    return Promise.reject(error);
  })

  // eslint-disable-next-line no-param-reassign
  if (accessToken) {
    request.headers = {
      ...(request.headers | {}),
      Authorization: `Bearer ${accessToken}`
    }
  }
  
  return request;
})

const isObjectEmpty = (object) => Object.values(object).every(x => x === null || x === '');

const getSortQueryParams = (sort) => {
    return Object.keys(sort).map((key) => {
        if (sort[key] === 2) {
          return `&sort=${key}[desc]`;
        } else if (sort[key] === 3) {
          return `&sort=${key}`;
        } else {
            return ''
        }
    })?.filter((value) => value !== '').join("&")
}

const getRangeQueryParams = (page, pageSize) => {
    let start = '0';
    let end = '10';
    if (typeof page !== 'undefined' && pageSize) {
        start = page > 0 ? (page * pageSize).toString() : '0';
        end = (page * pageSize + pageSize).toString();
    }
    const range = `range=${start}-${end}`;
    return range;
}

const getLeGe = (string) => {
    const str = string.replace('%', '');
    const tab = str.split('-')
    return { ge: tab[0], le: tab[1] }
}

const getFilterQueryParams = (data) => Object.keys(data).map(key => {
    if (['batt_serial_num'].includes(key) && data[key]) {
      return `${key}=${data[key]}`;
    } else if (Array.isArray(data[key]) && ['batt_type', 'operation', 'setpoint', 'op_cell_balance'].includes(key)) {
      return data[key].map(val => `${key}='${val}'`).join("&")
    } else if (key === 'op_date') {
      const op_date = moment(data['op_date'])
      return `op_date='${op_date.format("YYYY-MM-DD HH:mm:ss")}'`;
    } else if (key === 'startDate') {
      const startDate = moment(data['startDate'])
      return `op_date[ge]='${startDate.format("YYYY-MM-DD")}'`;
    } else if (key === 'endDate') {
      const endDate = moment(data['endDate']).add(1, 'days')
      return `op_date[lt]='${endDate.format("YYYY-MM-DD")}'`;
    } else if (key === 'soh') {

      return data[key].map(val => {
        const { le, ge } = getLeGe(SOH_OBJECT[val])
        return `soh[ge]=${ge}&soh[le]=${le}`;
      })
    } else if (key === 'soc') {
      return data[key].map(val => {
        const { le, ge } = getLeGe(SOC_OBJECT[val])
        return `soc[ge]=${ge}&soc[le]=${le}`;
      })
    } else if (key === 'manufacturer') {
      return data[key].map(val => `${key}='${val}'`).join("&")
    }
  })?.join("&");
  
/**
 * get all batteries
 */
export const getBatteries = async ({ page, pageSize, sort, filters }) => {
    const search_query = !isObjectEmpty(filters) ? '&' + getFilterQueryParams(filters) : '';
    const range_query = getRangeQueryParams(page, pageSize);
    const sort_query = getSortQueryParams(sort);
    const url = `/operations?${range_query}${sort_query}${search_query}`;
    const { data } = await api.get(url);
    return data;
};


/**
 * export batteries
 */
export const exportFile = async () => {
    const { data } = await api.get(
        `/export/list`,
        { responseType: 'blob' }
    );

    return data;
};
