import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Button, Link } from '@mui/material';
import { useNavigate } from "react-router-dom";

// components
import AccountPopover from './AccountPopover';

const APPBAR_DESKTOP = 92;


const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: "92px!important",
    backgroundColor: "#f2f2f2",
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
    }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ enableHelpdesk }) {
    const navigate = useNavigate();
    const goToHome = () => navigate("/", { replace: true });

    return (
        <AppBar>
            <ToolbarStyle>
                <Button onClick={goToHome}>
                    <img src="/logo.svg" alt="logo" style={{ width: 125 }} />
                </Button>

                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    {
                        !!enableHelpdesk
                            ? <Link href="https://share-eu1.hsforms.com/107Du2j7aTnSQM-0EisVZ5gf01yv">Besoin d'aide ?</Link>
                            : null
                    }
                    <AccountPopover />
                </Stack>
            </ToolbarStyle>
        </AppBar>

    );
}
