import React from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "../../config";
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

export default function Login() {

    var query = useQuery();

    const authRequest = {
        ...loginRequest,
        loginHint: query.get("login_hint")
    };

    return (
        <div>

            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
            >
            </MsalAuthenticationTemplate>
        </div>
    );
}