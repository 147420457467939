import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, .8)',
    position: 'fixed',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    zIndex: '1001',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  linearProgress: {
    width: '10rem'
  },
  pageLoader: {
    position: 'fixed',
    left: '50%',
    top: '40%'
  }
}));
