import { EventType, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import decode_jwt from "jwt-decode";
import { config, loginRequest } from "../config";

export const msal = new PublicClientApplication(config);

msal.addEventCallback((event) => {
  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE && event.error?.errorCode === 'monitor_window_timeout') {
    msal.acquireTokenRedirect({ ...loginRequest, });
  }
})

export const addToken = createAsyncThunk('User/authenticated', async (account) => {
  const accessToken = await msal.acquireTokenSilent({
    scopes: [],
    account: account,
  }).then((response) => response.accessToken)
  .catch((error) => {
    if (error.errorCode && InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
      return msal.acquireTokenRedirect({
        ...loginRequest,
        account
      });
    }
    return Promise.reject(error);
  })
  // see the access token claims reference at https://learn.microsoft.com/en-us/entra/identity-platform/access-token-claims-reference#payload-claims
  const accessTokenClaims = decode_jwt(accessToken)
  return {
    isUsingKioskTerminal: (accessTokenClaims.amr || []).includes('rsa') || ((accessTokenClaims.azpacr || "") === "2")
  }
});

const slice = createSlice({
  name: 'user',
  initialState: {
    isUsingKioskTerminal: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(addToken.fulfilled, (state, { payload }) => {
      state.isUsingKioskTerminal = payload.isUsingKioskTerminal
    })
  }
});

export default slice.reducer