import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveBatteries, applyFilters, resetFilters, applySort, gotoPage, changePageSize } from "../../slices/batteries";
import { FUNCTIONS, SOC_OBJECT, SOH_OBJECT, FONT_PRIMARY, END_CYCLE, LOADING } from '../../utils/constant';
import { exportFile } from '../../api/ApiCall';
import { formatDate } from '../../utils/misc';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Toolbar,
  Typography,
} from '@mui/material';
import { alpha, createTheme, MuiThemeProvider } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Header from '../../components/HeaderCell';
import ExportButton from '../../components/ExportButton';
import RefreshButton from '../../components/RefreshButton';
import DatePickerFilter from '../../components/DatePickerFilter';
import FilterButton from '../../components/FilterButton'
import InputFilter from '../../components/InputFilter';
import SelectFilter from '../../components/SelectFilter';
import NoRecordsFound from '../../components/NoRecordsFound';
import PageLoader from '../../components/PageLoader';

const downloadFile = (filename, contents) => {
  const url = window.URL.createObjectURL(new Blob([contents]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(155, 209, 101)',
      contrastText: '#fff'
    },
    secondary: {
      main: '#676767',
    }
  },
})

const RootContainer = styled(Paper)(({ theme }) => ({
  background: 'white'
}));

const TH = styled(TableCell)(({ theme }) => ({
  background: alpha('#f2f2f2', 0.5),
  fontFamily: FONT_PRIMARY,
  fontWeight: 'normal',
  border: "1px solid #ccc",
  textAlign: "center",
  padding: '5px',
}))

const TR = styled(TableRow)(({ theme }) => ({
  fontSize: 13,
  cursor: 'pointer',

  '&:nth-of-type(even)': {
    background: alpha('#f2f2f2', 0.5),
  },

  "&:hover": {
    background: alpha('rgb(155, 209, 101)', 0.08)
  }
}))

const TD = styled(TableCell)(({ theme }) => ({
  border: "1px solid #f2f2f2",
  textAlign: 'center',
  fontFamily: FONT_PRIMARY,
}))

const EmptyTable = () => (
  <TableRow sx={{ height: '490px' }}>
    <TableCell colSpan={9} align="center">
      <NoRecordsFound />
    </TableCell>
  </TableRow>
)

const Headers = ({ sort, onSortDirectionChanged }) => (
  <TableRow>
    <TH><Header title='Num. série' help="Numéro de série" enableSort={true} sortDirection={sort["batt_serial_num"]} onSortDirectionChanged={() => onSortDirectionChanged("batt_serial_num")} /></TH>
    <TH><Header title='Type' help="Type de batterie" /></TH>
    <TH><Header title='Date' help="Date de fin d'opération reportée" enableSort={true} sortDirection={sort["op_date"]} onSortDirectionChanged={() => onSortDirectionChanged("op_date")} /></TH>
    <TH><Header title='Mode' help="Fonction demandée pour l'opération" /></TH>
    <TH><Header title='Fin de cycle' help="Consigne de mise à SOC" /></TH>
    <TH><Header title='SOC relevé' help="SOC reporté en fin d'opération %" enableSort={true} sortDirection={sort["soc"]} onSortDirectionChanged={() => onSortDirectionChanged("soc")} /></TH>
    <TH><Header title='SOH relevé' help="SOH reporté en fin d'opération %" enableSort={true} sortDirection={sort["soh"]} onSortDirectionChanged={() => onSortDirectionChanged("soh")} /></TH>
    <TH><Header title='Equilibrage' help="Equilibrage de tension entre les cellules" /></TH>
    <TH><Header title='Fabricant' help="Nom du fabricant" /></TH>
  </TableRow>
)

const renderPercents = (percents) => [undefined, null].includes(percents)
  ? "N/A"
  : percents + "%"

const Filters = ({ filters, onApply, onReset }) => {
  const [pendingFilters, setPendingFilters] = useState(filters)

  const handleApply = () => onApply(pendingFilters)
  const handleReset = () => onReset()

  const setFilter = (field, value) => {
    setPendingFilters({
      ...pendingFilters,
      [field]: value
    })
  }

  const setDateRangeFilter = (startDate, endDate) => {
    setPendingFilters({
      ...pendingFilters,
      startDate,
      endDate
    })
  }

  useEffect(() => {
    setPendingFilters(filters)
  }, [filters])

  return (
    <Fragment>
      <TableRow>
        <TableCell><InputFilter name={"batt_serial_num"} onChange={setFilter} value={pendingFilters["batt_serial_num"]} placeholder="Numéro de série" /></TableCell>
        <TableCell><SelectFilter name={"batt_type"} onSaveFilter={setFilter} selection={pendingFilters["batt_type"]} placeholder="Type" options={{ "SI": "SI", "SS": "SS" }} /></TableCell>
        <TableCell><DatePickerFilter onSaveFilter={setDateRangeFilter} startDate={pendingFilters['startDate']} endDate={pendingFilters['endDate']} /></TableCell>
        <TableCell><SelectFilter name={"operation"} onSaveFilter={setFilter} selection={pendingFilters["operation"]} placeholder="Fonction" options={FUNCTIONS} /></TableCell>
        <TableCell><SelectFilter name={"setpoint"} onSaveFilter={setFilter} selection={pendingFilters["setpoint"]} placeholder="Consigne" options={END_CYCLE} /></TableCell>
        <TableCell><SelectFilter name={"soc"} onSaveFilter={setFilter} selection={pendingFilters["soc"]} placeholder="SOC relevé" options={SOC_OBJECT} /></TableCell>
        <TableCell><SelectFilter name={"soh"} onSaveFilter={setFilter} selection={pendingFilters["soh"]} placeholder="SOH relevé" options={SOH_OBJECT} /></TableCell>
        <TableCell><SelectFilter name={"op_cell_balance"} onSaveFilter={setFilter} selection={pendingFilters["op_cell_balance"]} placeholder="Equilibrage" options={{ 0: "NOK", 1: "OK" }} /></TableCell>
        <TableCell><SelectFilter name={"manufacturer"} onSaveFilter={setFilter} selection={pendingFilters["manufacturer"]} placeholder="Fabricant" options={['Leclanche', 'SAFT'].map(o => ({ [o]: o }))} /></TableCell>
      </TableRow>
      {pendingFilters === filters && Object.keys(pendingFilters).length === 0
        ? null
        : (
          <TableRow>
            <TableCell sx={{ display: 'flex', flexDirection: "row", padding: 0 }}>
              <FilterButton variant="apply" onClick={handleApply}>Appliquer</FilterButton>
              <FilterButton variant="reset" onClick={handleReset}>Réinitialiser</FilterButton>
            </TableCell>
          </TableRow>
        )
      }
    </Fragment>
  )
}

export default function Batteries() {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const { batteries, total, page, pageSize, filters, sort, loading } = useSelector((state) => state.batteries)
  const user = useSelector((state) => state.user)

  const onApplyFilter = (pendingFilters) => dispatch(applyFilters(pendingFilters));
  const onCleanFilter = () => dispatch(resetFilters());
  const onPageChange = (event, newPage) => dispatch(gotoPage(newPage))
  const onRowsPerPageChange = (event) => dispatch(changePageSize(parseInt(event.target.value)))
  const onSortDirectionChanged = (field) => dispatch(applySort({ [field]: !!sort[field] ? sort[field] % 3 + 1 : 2 }));
  const goToDetail = (batteryKey) => navigate(`/detail/${batteryKey}`, { replace: true })

  const downloadCSVExport = () => exportFile()
    .then((response) => downloadFile(`operations-${moment().format('DD.MM.YYYY HH:mm')}.csv`, response))

  const search = () => {
    dispatch(retrieveBatteries({ page, pageSize, filters, sort }))
  }

  useEffect(search, []);

  return (
    <RootContainer>
      {loading === LOADING.PENDING && <PageLoader withOverlay />}

      <MuiThemeProvider theme={theme}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="#676767" >
            Tableau de bord
          </Typography>

          { user.isUsingKioskTerminal
            ? <RefreshButton onClick={search} />
            : <ExportButton onClick={downloadCSVExport} />
          }
        </Toolbar>

        <TableContainer>
          <Table>
            <TableHead>
              <Headers sort={sort} onSortDirectionChanged={onSortDirectionChanged} />
              <Filters filters={filters} onApply={onApplyFilter} onReset={onCleanFilter} />
            </TableHead>
            <TableBody>
              {batteries.length === 0
                ? (<EmptyTable />)
                : batteries.map((battery) => (
                  <TR key={battery.batteryKey} onClick={() => goToDetail(battery.batteryKey)}>
                    <TD>{battery.batt_serial_num}</TD>
                    <TD>{battery.batt_type}</TD>
                    <TD>{formatDate(battery.op_date)}</TD>
                    <TD>{FUNCTIONS[battery.operation]}</TD>
                    <TD>{END_CYCLE[battery.setpoint]}</TD>
                    <TD>{renderPercents(battery?.soc_value)}</TD>
                    <TD>{renderPercents(battery?.soh_value)}</TD>
                    <TD>{{ 0: "NOK", 1: "OK" }[battery.op_cell_balance]}</TD>
                    <TD>{battery.manufacturer}</TD>
                  </TR>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={parseInt(total)}
          page={parseInt(page)}
          onPageChange={onPageChange}
          rowsPerPage={parseInt(pageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          onRowsPerPageChange={onRowsPerPageChange}
          showFirstButton
          showLastButton
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          labelRowsPerPage="lignes par page:"
          getItemAriaLabel={(type) => ({
            first: "Première page",
            last: "Dernière page",
            next: "Page suivante",
            previous: "Page précédente"
          }[type])}
          SelectProps={{
            native: true,
            style: {
              fontFamily: FONT_PRIMARY,
              fontSize: '.875rem'
            }
          }}
        />
      </MuiThemeProvider>
    </RootContainer>
  );
}
